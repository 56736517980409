// to easily add new projects, create project object below, include image file, and import image above
import drumkit from "../images/projectImages/drumkit.png";
import weatherReact from "../images/projectImages/weatherReact.png";
import dictionary from "../images/projectImages/dictionary.png";
import guessNumber from "../images/projectImages/guessNumber.png";
import pigGame from "../images/projectImages/pigGame.png";
import statiecheck from "../images/projectImages/statiecheck.png";
// import nasa from "../images/projectImages/nasa.png";
// import ponggame from "../images/projectImages/ponggame.png";
export const projects = [
  // {
  //   name: "NASA Dashboard",
  //   github: "https://github.com/KarenStewart1/nasa-dashboard",
  //   site: "http://karenstewartportfolio.ml:8000",
  //   imgsrc: nasa,
  //   imgalt: "NASA project",
  //   info: [
  //     "Javascript",
  //     "React",
  //     "Node",
  //     "Express",
  //     "Jest",
  //     "PM2",
  //     "MongoDB",
  //     "Github Actions",
  //     "Docker",
  //     "Oracle",
  //   ],
  //   description:
  //     "This NASA Mission Control Dashboard lets you schedule launches to habitable planets and shows all upcoming and historical SpaceX launches. I built this app with Javascript, React, Node, Express and MongoDB. I set up a CI pipeline with Github Actions which ran automated tests that I created with Jest. I containerized the app with Docker and deployed it to Oracle. The app connects with a SpaceX API to retrieve all past and future SpaceX launches. ",
  //   featured: true,
  // },
  // {
  //   name: "Two-Player Pong Game",
  //   github: "https://github.com/KarenStewart1/pong",
  //   site: "http://karenstewartportfolio.tk:4000",
  //   imgsrc: ponggame,
  //   imgalt: "two player pong game",
  //   imgClassName: "img-fluid",
  //   info: ["Javascript", "Node", "socket.io", "Docker", "DigitalOcean"],
  //   description:
  //     "I built the back-end for this two player pong game with Javascript, Node and socket.io.  I containerized the app with Docker and deployed it to a DigitalOcean droplet.",
  //   featured: true,
  // },
  {
    name: "StatieCheck.nl",
    site: "https://statiecheck.nl",
    imgsrc: statiecheck,
    imgalt: "statiecheck",
    info: [
      "HTML/CSS",
      "Javascript",
      "React",
      // "C#",
      // "ASP.NET Core",
      "NodeJs",
      "PostgreSQL",
      // "StadiaMaps",
      // "OpenStreetMaps",
      // "OpenFreeMap",
      // "Leaflet",
      // "Tailwind",
    ],
    description:
      "I built this public community driven web app to help people in the Netherlands find working statiegeld machines. The responsive frontend was built primarily for mobile with React. The backend was originally built with C# & ASP.NET Core with a PostgreSQL database. Later the backend was rebuilt with Node.js. The database is hosted on a digital ocean droplet, while the Backend and Frontend are hosted on Render.com. Google Analytics is integrated to track website traffic and optimize performance.",
    // rate limiting, distance limiting, marketed it on reddit with posts and ads, firewalls, public
    featured: true,
  },
  {
    name: "Pig Dice Game",
    github: "https://github.com/KarenStewart1/pig-game",
    site: "https://gallant-albattani-11fd68.netlify.app/",
    imgsrc: pigGame,
    imgalt: "pig dice game",
    info: ["HTML/CSS", "Javascript", "React"],
    description:
      "I built this two-player dice game using HTML/CSS, Javscript and React. Hosted on Netlify.",
    featured: true,
  },
  {
    name: "Drum Kit",
    github: "https://github.com/KarenStewart1/Drum-Kit-Project",
    site: "https://pensive-franklin-f3e139.netlify.app/",
    imgsrc: drumkit,
    imgalt: "drum kit project",
    imgClassName: "img-fluid drum-kit-image",
    info: ["HTML/CSS", "Javascript", "React"],
    description:
      "I built this drum kit using HTML/CSS, Javascript and React. It plays a different note depending on which key you press or button you click. Hosted on Netlify.",
    featured: false,
  },
  {
    name: "Guess the Number Game",
    github: "https://github.com/KarenStewart1/guess-number-game",
    site: "https://hungry-roentgen-b5a64a.netlify.app/",
    imgsrc: guessNumber,
    imgalt: "guess the number game project",
    info: ["HTML/CSS", "Javascript", "React"],
    description:
      "I built this game using HTML/CSS, Javscript and React. It's a game where you try to guess the secret number and keep playing to beat your high score. Hosted on Netlify.",
    featured: false,
  },
  {
    name: "Weather App",
    github: "https://github.com/KarenStewart1/Weather-App-React",
    site: "https://determined-swirles-225367.netlify.app/",
    imgsrc: weatherReact,
    imgalt: "weather app project",
    imgClassName: "img-fluid weather-image",
    info: ["HTML/CSS", "Javascript", "React"],
    description:
      "I built this weather app using HTML/CSS, Javascript, and React. It uses real-time data to provide a 5-day forecast and it uses fun animated images to display the weather. Hosted on Netlify.",
    featured: true,
  },
  {
    name: "Dictionary App",
    github: "https://github.com/KarenStewart1/React-Dictionary-App",
    site: "https://vibrant-beaver-c1542e.netlify.app/",
    imgsrc: dictionary,
    imgalt: "dictionary app project",
    imgClassName: "img-fluid",
    info: ["HTML/CSS", "Javascript", "React"],
    description:
      "I built this dictionary app using HTML/CSS, Javscript and React. Searching for a word will return an audio pronounciation, definition(s), synonyms and related images. Hosted on Netlify.",
    featured: false,
  },
];

// UNUSED PROJECTS
// // import weather from "../images/projectImages/weather.png";
// {
//   name: "Weather App - Vanilla Javascript",
//   github: "https://github.com/KarenStewart1/Weather-App-Project",
//   site: "https://quizzical-borg-b7e78f.netlify.app/",
//   imgalt: "my weather app project",
//   imgsrc: weather,
//   imgClassName: "img-fluid",
//   info: ["HTML/CSS", "Javascript"],
//   description:
//     "I built this weather app using CSS, HTML and Javascript. It uses real-time data to provide a 5-day forecast for the city you search for or your current location.",
//   featured: false,
// },
